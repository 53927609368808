import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loadable from 'react-loadable'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom"

import config from './config'
import { theme } from './styles/theme'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './styles/globalStyle'

import Layout from './components/Layout'
import Loading2 from './components/Loading'
import BusinessLoading from './components/BusinessLoading'

import {
  onAuthStateChanged,
  onAuthRedirectResult,
  signInWithCustomToken
} from './redux/actions/action_permission'
import { chkRedirect } from './libs/AppRedirect'

const Loading = () => <div> <Loading2 /> </div>;

const Dashboard = Loadable({
  loader: () => import('./pages/Dashboard'),
  loading: Loading,
})
const EditProfile = Loadable({
  loader: () => import('./pages/EditProfile'),
  loading: Loading,
})
const ResetPasswordUser = Loadable({
  loader: () => import('./pages/ResetPasswordUser'),
  loading: Loading,
})
const ChangeEmail = Loadable({
  loader: () => import('./pages/ChangeEmail'),
  loading: Loading,
})
const Product = Loadable({
  loader: () => import('./pages/Product'),
  loading: Loading,
})
const RedirectPage = Loadable({
  loader: () => import('./pages/RedirectPage'),
  loading: Loading,
})
const Package = Loadable({
  loader: () => import('./pages/Package'),
  loading: Loading,
})
const Invoices = Loadable({
  loader: () => import('./pages/Invoices'),
  loading: Loading,
})
const AddressInvoices = Loadable({
  loader: () => import('./pages/AddressInvoice'),
  loading: Loading,
})

const Payment = Loadable({
  loader: () => import('./pages/Payment'),
  loading: Loading,
})

const PaymentBalance = Loadable({
  loader: () => import('./pages/PaymentBalance'),
  loading: Loading,
})

const Usages = Loadable({
  loader: () => import('./pages/Usages'),
  loading: Loading,
})

const { TRACE } = config.diagnostic

class AppRoute extends React.Component {
  componentDidMount() {
    let { store_config: { application } } = this.props

    TRACE(`${application.name} ${application.version}`);
    this.props.onAuthStateChanged()
    this.props.onAuthRedirectResult()

    this.checkPermission();

    document.body.onfocus = () => {
      this.checkPermission();
    }
  }

  componentDidUpdate() {
    let { store_permission } = this.props
    if (store_permission.isError) {
      window.location.href = process.env.REACT_APP_LOYALTY
    }
  }

  checkPermission() {
    let { store_permission } = this.props
    chkRedirect(store_permission.isLogin).then((token) => {
      if (!token) {
        window.location.href = process.env.REACT_APP_LOYALTY;
      } else {
        this.props.signInWithCustomToken(token);
      }
    }, () => {
      //console.log("[auth] ok");
    });
  }

  render() {
    let { isLogin, isVerify, isPageLoading } = this.props.store_permission
    return (
      <ThemeProvider theme={theme}><GlobalStyle />
        {
          isPageLoading
            ? <Loading />
            : <Router>
              <>
                <Switch>
                  {
                    isLogin && isVerify && (
                      <BusinessLoading>
                        <Switch>
                          <Route exact path="/" component={RedirectPage} />
                          <Layout>
                            <Switch>
                              <Route exact path="/dashboard" component={Dashboard} />
                              <Route exact path="/product" component={Product} />
                              <Route exact path="/editprofile" component={EditProfile} />
                              <Route exact path="/resetpassword" component={ResetPasswordUser} />
                              <Route exact path="/changeemail" component={ChangeEmail} />
                              <Route exact path="/package" component={Package} />
                              <Route exact path="/invoices" component={Invoices} />
                              <Route exact path="/usages" component={Usages} />
                              <Route exact path="/address" component={AddressInvoices} />
                              <Route exact path="/payment" component={Payment} />
                              <Route exact path="/balance" component={PaymentBalance} />
                              <Redirect to="/" />
                            </Switch>
                          </Layout>
                        </Switch>
                      </BusinessLoading>
                    )
                  }
                </Switch>
              </>
            </Router>
        }
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language,
    store_config: state.config,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    onAuthStateChanged, onAuthRedirectResult, signInWithCustomToken
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute)