export const theme = {
  primary: '#546174',
  secondary: '#7d889a',
  input_placeholder: '#B1BCCF',
  gray: '#9da6ad',
  disabled: '#e4ebf1',
  green: '#00C851',
  red: '#FF4444',
  orange: '#FFBB33',
  yellow: '#ffc107',
  blue: '#33B5E5',
  white: '#fafafa',
  white_2: '#F0F0F0',
  white_1: '#FFFFFF',
  white_border: '#e6e6e6',
  white_hilight: '#f0f0f0',
  border_line: '#dee2e6',
  
  font_xxl: '20px',
  font_xl: '18px',
  font_l: '16px',
  font_m: '14px',
  font_s: '12px',
  font_xs: '10px',
  font_xxs: '8px',
  font_bold: '600'
}