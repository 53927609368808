import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import { Title2, Title3 } from '../Font'

import {
  IconMenu,
  MenuIcon,
  MenuAllItem,
  MenuItem,
  TitleMenu,
  SubMenu,
  MenuIconAngle,
} from './sidebar.style'

class sidebarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }

    this.toggleSidebar = this.toggleSidebar.bind(this)
  }

  toggleSidebar() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  componentDidMount() {
    let { value } = this.props
    let { location: { pathname } } = this.props
    let { store_language: { dictionary } } = this.props
    let itemsMenu = value.subMenu.filter(f => f.show)
    let annualMenu = ['/usages', '/balances'].indexOf(pathname) > -1 && value.title === dictionary.payment_method

    this.setState({ isOpen: itemsMenu.map(v => v.href).indexOf(pathname) > -1 || annualMenu })
  }

  render() {
    let { isOpen } = this.state
    let { value } = this.props

    let itemsMenu = value.subMenu.filter(f => f.show)

    return <TitleMenu>
      <SubMenu onClick={this.toggleSidebar}>
        <Title2 bold>{value.title}</Title2>
        <MenuIconAngle isOpen={isOpen}>
          <IconMenu active className='fas fa-angle-down' />
        </MenuIconAngle>
      </SubMenu>

      <MenuAllItem isOpen={isOpen} itmesSubMenu={itemsMenu.length} >
        {
          itemsMenu.map((subValue, subKey) => {
            let { location: { pathname } } = this.props
            let link = subValue.disable ? window.location.pathname : subValue.href
            let active = subValue.href === pathname

            return <Link key={`sub_${subKey}`} to={link} style={{ textDecoration: 'none' }}>
              <MenuItem active={active} disable={subValue.disable}>
                <MenuIcon>
                  <IconMenu className={subValue.icon} active={active} />
                </MenuIcon>
                <Title3 bold={active} secondary={!active}>{subValue.title}</Title3>
              </MenuItem>
            </Link>
          })
        }
      </MenuAllItem>
    </TitleMenu>
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language,
    store_sidebar: state.sidebar,
    store_payment: state.payment,
    store_config: state.config
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(sidebarMenu))