import styled, { css } from 'styled-components'

const Button = styled.button`
  ${props => props.fluid ? 'width: 100%;' : null}  
  border-radius:  ${props => props.pill ? '30px' : '4px'};
  padding: .375rem .75rem;
  min-width: 80px;
  outline:none;
  box-shadow:none;
  cursor: pointer;
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.font_m};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
  }
  &:disabled{
    background: #eee;
    border: 1px solid #eee; 
    color: ${props => props.theme.secondary};
  }

  &:hover{
    opacity: 0.9; 
  }
  ${props => props.bold && css`font-weight: 600;`}
  ${props => props.xs && css`font-size: 0.7rem; padding: 5px 15px;`}
  ${props => props.sm && css`font-size: 0.8rem; padding: 5px 15px;`}
  ${props => props.md && css`font-size: 1rem; padding: 5px 15px;`}
`

export const PrimaryBtn = styled(Button)`
  background-color: ${props => props.theme.primary};
  border: 1px solid ${props => props.theme.primary};
`

export const SuccessBtn = styled(Button)`
  background-color: ${props => props.theme.green};
  border: 1px solid ${props => props.theme.green};
`

export const DangerBtn = styled(Button)`
  background-color: ${props => props.theme.red};
  border: 1px solid ${props => props.theme.red};
`

export const InvertBtn = styled(Button)`
  background-color: transparent;
  border: 1px solid transparent;
  color: ${props => props.theme.secondary};
`

export const WarningBtn = styled(Button)`
  background-color: ${props => props.theme.orange};
  border: 1px solid ${props => props.theme.orange};
`