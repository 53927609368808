import { combineReducers } from 'redux'
import permission from './reducers/reducer_permission'
import language from './reducers/reducer_language'
import sidebar from './reducers/reducer_sidebar'
import payment from './reducers/reducer_payment'
import business from './reducers/reducer_business'
import invoices from './reducers/reducer_invoices'
import config from './reducers/reducer_config'
import balance from './reducers/reducer_balance'

const Reducers = combineReducers({
  permission,
  language,
  sidebar,
  payment,
  business,
  invoices,
  config,
  balance,
})

export default Reducers