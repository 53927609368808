import { BALANCE } from '../action_const'

let initState = {
  balance: [],
  credits: [],
  creditsBalance: 0,
  creditsPage: 0,
  isLoading: false,
}

export default (state = initState, action) => {
  switch (action.type) {
    case BALANCE.GET_CREDIT_BALANCE:
      return Object.assign({}, state, {
        balance: (action && action.data) || []
      })

    case BALANCE.GET_USAGE_CREDIT:
      return Object.assign({}, state, {
        credits: (action && action.data && action.data.usages) || [],
        creditsBalance: (action && action.data && action.data.balances) || 0,
        creditsPage: (action && action.data && action.data.balance_page) || 0,
        isLoading: false
      })

    case BALANCE.LOADING_USAGE_CREDIT:
      return Object.assign({}, state, {
        isLoading: true
      })

    case BALANCE.CATCH_CREDIT_BALANCE:
    default:
      return state
  }
}