export const PERMISSION = {
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_UNREGISTERED: 'USER_UNREGISTERED',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',
  GET_SOCIAL_CREDENTIAL: 'GET_SOCIAL_CREDENTIAL',
  EMAIL_ALREADY_VERIFIED: 'EMAIL_ALREADY_VERIFIED',

  SET_REDIRECT_ROUTE: 'SET_REDIRECT_ROUTE',
  USER_NOT_AUTH: 'USER_NOT_AUTH',
  REMOVE_SOCIAL_CREDENTAIL: 'REMOVE_SOCIAL_CREDENTAIL',
  REMOVE_LOGIN_ERROR: 'REMOVE_LOGIN_ERROR',
  UPDATE_AVATAR_USER: 'UPDATE_AVATAR_USER',
  UPDATE_PROFILE_USER: 'UPDATE_PROFILE_USER',
  UPDATE_USER_LOADING: 'UPDATE_USER_LOADING',
  SENT_EMAIL_RESETPASSWORD: 'SENT_EMAIL_RESETPASSWORD',
  SENT_EMAIL_RESETPASSWORD_LOADING: 'SENT_EMAIL_RESETPASSWORD_LOADING',
  PERMISSION_IS_LOADING: 'PERMISSION_IS_LOADING',
  PERMISSION_IS_LOADING_SUCCESS: 'PERMISSION_IS_LOADING_SUCCESS',
  CHANGE_EMAIL_LOADING: 'CHANGE_EMAIL_LOADING',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
}

export const LANGUAGE = {
  SELECT_LANGUAGE: 'SELECT_LANGUAGE',
}

export const SIDEBAR = {
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
}

export const PAYMENT = {
  PAYMENT_LOADING: 'PAYMENT_LOADING',
  PAYMENT_LOADING_SUCCESS: 'PAYMENT_LOADING_SUCCESS',
  CREATE_PAYMENT_CUSTOMER_SUCCESS: 'CREATE_PAYMENT_CUSTOMER_SUCCESS',
  GET_PAYMENT_CUSTOMER_SUCCESS: 'GET_PAYMENT_CUSTOMER_SUCCESS',
  GET_PAYMENT_CUSTOMER_FAIL: 'GET_PAYMENT_CUSTOMER_FAIL',
  GET_PAYMENT_CUSTOMER_CARD_SUCCESS: 'GET_PAYMENT_CUSTOMER_CARD_SUCCESS',
  GET_PAYMENT_CUSTOMER_CARD_FAIL: 'GET_PAYMENT_CUSTOMER_CARD_FAIL',
  GET_PAYMENT_PRODUCT_LIST: 'GET_PAYMENT_PRODUCT_LIST',
  UPDATE_DEFAULT_SOURCE: 'UPDATE_DEFAULT_SOURCE',
  GET_PAYMENT_ADDRESS_SUCCESS: 'GET_PAYMENT_ADDRESS_SUCCESS',
  GET_PAYMENT_ADDRESS_FAIL: 'GET_PAYMENT_ADDRESS_FAIL',
  PAYMENT_ADDRESS_LOADING: 'PAYMENT_ADDRESS_LOADING'
}

export const BUSINESS = {
  BUSINESS_LOADING: 'BUSINESS_LOADING',
  GET_BUSINESS_LIST: 'GET_BUSINESS_LIST',
}

export const INVOICE = {
  INVOICE_LOADING: 'INVOICE_LOADING',
  GET_CUSTOMER_INVOICES: 'GET_CUSTOMER_INVOICES',
  RETRY_CHARGE_INVOICE: 'RETRY_CHARGE_INVOICE',
  BILLING_DETAIL: 'BILLING_DETAIL',
  SET_LOADING_INFO: 'SET_LOADING_INFO',
  CLEAR_BILLING_DETAIL: 'CLEAR_BILLING_DETAIL',
  GET_CREDITS_USAGE_INVOICE: 'GET_CREDITS_USAGE_INVOICE'
}

export const BALANCE = {
  GET_CREDIT_BALANCE: 'GET_CREDIT_BALANCE',
  CATCH_CREDIT_BALANCE: 'CATCH_CREDIT_BALANCE',
  GET_USAGE_CREDIT: 'GET_USAGE_CREDIT',
  LOADING_USAGE_CREDIT: 'LOADING_USAGE_CREDIT',
}