import { PAYMENT } from '../action_const'
import axios from 'axios'
import { getCookie } from '../../libs/cookie'
import { getError } from '../../libs/my2c2p'

export const getCustomerPayment = (business_list, callback) => {
  return dispatch => {
    dispatch({ type: PAYMENT.PAYMENT_LOADING })
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/customerPayment`,
      headers: { authorization: getCookie('__fbToken') },
      data: {
        business_list
      }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data)
      return dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_SUCCESS, data: data })

    }).catch(() => {
      if (typeof callback === 'function') callback(getError())
      return dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_FAIL })

    })
  }
}

export const createCustomerPayment = () => {
  return dispatch => {
    dispatch({ type: PAYMENT.PAYMENT_LOADING })
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment`,
      headers: { authorization: getCookie('__fbToken') }
    }).then(({ data }) => {
      dispatch({ type: PAYMENT.CREATE_PAYMENT_CUSTOMER_SUCCESS, data: data })

    }).catch(() => {
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_FAIL })

    })
  }
}

export const getCustomerCreditCard = (business_code, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/card/paymentCard`,
      headers: { authorization: getCookie('__fbToken') }, 
      data: {
        business_code
      }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data)
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_CARD_SUCCESS, data: data })

    }).catch((err) => {
      if (typeof callback === 'function') callback(err)
      dispatch({ type: PAYMENT.GET_PAYMENT_CUSTOMER_CARD_FAIL })

    })
  }
}

export const createCreditCard = (card, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/card`,
      headers: { authorization: getCookie('__fbToken') },
      data: { card: card }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.result)
        dispatch({ type: PAYMENT.PAYMENT_LOADING })
        setTimeout(() => { dispatch({ type: PAYMENT.PAYMENT_LOADING_SUCCESS }) }, 500)

      } else {
        let { internal_code } = data.error
        if (typeof callback === 'function') callback(getError(internal_code.code))
      }

    }).catch(() => {
      if (typeof callback === 'function') callback(getError())
    })
  }
}

export const deleteCreditCard = (card_token, callback) => {
  return dispatch => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/card`,
      headers: { authorization: getCookie('__fbToken') },
      data: { card_token: card_token }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.result)
        dispatch({ type: PAYMENT.PAYMENT_LOADING })
        setTimeout(() => { dispatch({ type: PAYMENT.PAYMENT_LOADING_SUCCESS }) }, 500)

      } else
        if (typeof callback === 'function') callback(getError())

    }).catch(() => {
      dispatch({ type: PAYMENT.PAYMENT_LOADING })
      setTimeout(() => {
        dispatch({ type: PAYMENT.PAYMENT_LOADING_SUCCESS })
      }, 500)
    })
  }
}

export const getCustomerProduct = (callback, callback_fail) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/product`,
      headers: { authorization: getCookie('__fbToken') },
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(data.result)
      return dispatch({ type: PAYMENT.GET_PAYMENT_PRODUCT_LIST, data: data })

    }).catch(err => {
      if (typeof callback_fail === 'function') callback_fail()
      return dispatch({ type: PAYMENT.GET_PAYMENT_PRODUCT_LIST, data: {} })

    })
  }
}

export const defaultCreditCard = (card_token, business_code, callback) => {
  return dispatch => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/card`,
      headers: { authorization: getCookie('__fbToken') },
      data: { 
        card_id: card_token,
        business_code: business_code 
      }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.result)
        dispatch({ type: PAYMENT.UPDATE_DEFAULT_SOURCE, data: data.data })
        dispatch({ type: PAYMENT.PAYMENT_LOADING })
        setTimeout(() => {
          return dispatch({ type: PAYMENT.PAYMENT_LOADING_SUCCESS })
        }, 200)

      } else
        if (typeof callback === 'function') return callback(getError())


    }).catch(err => {
      if (typeof callback === 'function') return callback(getError())

    })
  }
}

export const getCustomerAddress = (business_code, callback) => {
  return dispatch => {
    dispatch({ type: PAYMENT.PAYMENT_ADDRESS_LOADING })
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/address/getAddress`,
      headers: { authorization: getCookie('__fbToken') },
      data: { business_code: business_code }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null,data.result)
      return dispatch({ type: PAYMENT.GET_PAYMENT_ADDRESS_SUCCESS, data: data.data })
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: PAYMENT.GET_PAYMENT_ADDRESS_FAIL, data: {} })

    })
  }
}

export const createCustomerAddress = (data, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/address`,
      headers: { authorization: getCookie('__fbToken') },
      data: { address: data }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null,data.result)
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
    })
  }
}

export const updateCustomerAddress = (data, callback) => {
  return dispatch => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_RP_AUTH_API}/payment/address`,
      headers: { authorization: getCookie('__fbToken') },
      data: { address: data }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null,data.result)
      // return dispatch({ type: PAYMENT.GET_PAYMENT_PRODUCT_LIST, data: data })

    }).catch(err => {
      if (typeof callback === 'function') callback(err)
      // return dispatch({ type: PAYMENT.GET_PAYMENT_PRODUCT_LIST, data: {} })

    })
  }
}