import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import Sidebar from 'react-sidebar'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  SidebarBox,
  PointspotLogoBox,
  PointspotLogo,
  GroupMenu,
  UserDetail,
  UserImage,
  GroupBottom,
} from './sidebar.style'

import { PrimaryBtn, InvertBtn } from '../Buttom'
import { Title2, Title3 } from '../Font'

import Logo from '../../assets/image/logo.svg'
import defaultImage from '../../assets/image/default-avatar.png'

import { toggleSidebar } from '../../redux/actions/action_sidebar'
import { getCustomerPayment } from '../../redux/actions/action_payment'

import SidebarMenu from './sidebarMenu'
import { getBusinessList } from '../../redux/actions/action_business'

class SideBarList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isShow: false
    }
    this.toggleModalConfirmSignOut = this.toggleModalConfirmSignOut.bind(this)
  }

  toggleModalConfirmSignOut() {
    this.setState({ isShow: !this.state.isShow })
  }

  componentDidMount() {
    this.props.getBusinessList((err, data) => {
      let business_list = data.data
        .filter((b) => b.fk_privilege_group_id === 1)
        .map((b) => {
          return b.business_code;
        });
      this.props.getCustomerPayment(business_list)
    })
  }

  render() {
    let { store_payment: { payment } } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_permission: { profile } } = this.props
    let { store_config: { application } } = this.props
    let { store_business: { business_info } } = this.props
    let customer_token_id = _.get(payment, ['user', 'customer_token_id'])
    let showInvoice = _.get(payment, ['payment', 'invoice_history'])
    let hasCredit = _.get(payment, ['payment', 'prepaidCredit'])
    const annualPackage = ['ps-starter', 'ps-professional', 'ps-enterprise']
    let showCreditMenu = business_info.filter(b => b.fk_privilege_group_id === 1 && ["annual", "pre-paid"].indexOf(b.subscription_payment_type) > -1 && b.business_status !== 'archive').length > 0 || hasCredit;
    let showUsageMenu = business_info.filter(b => b.fk_privilege_group_id === 1 && annualPackage.indexOf(b.current_package) > -1 && b.business_status !== 'archive').length > 0;

    let menu = [{
      show: false,
      title: 'Dashboard',
      icon: 'fas fa-chart-pie',
      href: '/dashboard',
    }, {
      show: true,
      title: dictionary.user_information,
      subMenu: [{
        show: true,
        title: dictionary.edit_profile,
        icon: 'fas fa-user-edit',
        href: '/editprofile',
      }, {
        show: true,
        title: dictionary.change_email,
        icon: 'fas fa-envelope',
        href: '/changeemail',
      }, {
        show: true,
        title: dictionary.reset_password,
        icon: 'fas fa-user-lock',
        href: '/resetpassword',
      }, {
        show: true,
        title: dictionary.product,
        icon: 'fas fa-box-open',
        href: '/product',
      }]
    }, {
      show: false,
      title: dictionary.package,
      icon: 'fas fa-box-open',
      href: '/package',
    }, {
      show: true,
      title: dictionary.payment_method,
      subMenu: [{
        show: true,
        title: `${dictionary.credit_card} / ${dictionary.debit_card}`,
        icon: 'fas fa-credit-card',
        href: '/payment',
      }, {
        show: showInvoice,
        disable: !Boolean(customer_token_id),
        title: dictionary.list_invoice_receipt,
        icon: 'fas fa-tasks',
        href: '/invoices',
      }, {
        show: showUsageMenu,
        disable: !Boolean(customer_token_id),
        title: dictionary.usages_receipt,
        icon: 'fas fa-edit',
        href: '/usages',
      }, {
        show: true,
        disable: !Boolean(customer_token_id),
        title: dictionary.address_invoice_receipt,
        icon: 'fas fa-map-marked-alt',
        href: '/address',
      }, {
        show: showCreditMenu,
        disable: !Boolean(customer_token_id),
        title: dictionary.credit,
        icon: 'fas fa-coins',
        href: '/balance',
      }]
    }]

    return <>
      <SidebarBox>
        <PointspotLogoBox>
          <PointspotLogo alt='' src={Logo} />
          <h3 className='text-center'>Account</h3>
        </PointspotLogoBox>

        <UserDetail>
          <UserImage alt='' src={profile.photoURL || defaultImage} />
          <div className='w-100 text-center'>
            <Title2 bold className='pb-1'>{`${profile.name ? profile.name : ''} ${profile.last_name ? profile.last_name : ''}`}</Title2>
            <Title3 secondary>{`${profile.phone ? profile.phone : ''}`}</Title3>
            <Title3 secondary>{`${profile.email ? profile.email : ''}`}</Title3>
          </div>
        </UserDetail>

        <GroupMenu>
          {
            menu.map((value, key) => {
              return value.show && value.subMenu && value.subMenu.filter(f => f.show).length > 0
                && <SidebarMenu {...this.props} key={key} value={value} />
            })
          }
        </GroupMenu>

        <GroupBottom>
          <div className='p-2'>
            <PrimaryBtn fluid bold sm onClick={this.toggleModalConfirmSignOut}>{dictionary.signout}</PrimaryBtn>
          </div>
          <div className='border-top d-flex align-items-center justify-content-between' style={{ padding: '5px 15px' }}>
            <Title3 className='m-1'>{application.name}</Title3>
            <Title3 className='m-1'>{`v.${application.version}`}</Title3>
          </div>
        </GroupBottom>
      </SidebarBox>

      {
        this.state.isShow
          ? <Modal centered fade isOpen={this.state.isShow}>
            <ModalHeader>
              <Title2 bold>{dictionary.notification}</Title2>
            </ModalHeader>
            <ModalBody>
              <div className='d-flex align-items-center justify-content-center' style={{ minHeight: 100 }}>
                <Title3>{dictionary.confirm_logout}</Title3>
              </div>
            </ModalBody>
            <ModalFooter>
              <InvertBtn bold sm onClick={this.toggleModalConfirmSignOut}>{dictionary.cancel}</InvertBtn>
              <PrimaryBtn bold sm onClick={() => { window.location.href = process.env.REACT_APP_LOYALTY + "/signout" }}>{dictionary.signout}</PrimaryBtn>
            </ModalFooter>
          </Modal>
          : null
      }
    </>
  }
}

class SidebarComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { docked: true }
  }

  componentDidMount() {
    this.setState({ docked: window.innerWidth >= 992 })
    window.onresize = () => {
      this.setState({ docked: window.innerWidth >= 992 })
    }
  }

  render() {
    let { store_sidebar, toggleSidebar } = this.props
    return (
      <Sidebar
        touch={false}
        shadow={!false}
        onSetOpen={toggleSidebar}
        docked={this.state.docked}
        open={store_sidebar.isShowSidebar}
        sidebar={<SideBarList  {...this.props} />}
        styles={{
          sidebar: {
            zIndex: 1030,
            width: '100%',
            minWidth: '290px',
            maxWidth: '290px',
            background: '#FFF'
          },
          content: {
            overflowY: 'auto',
          },
          overlay: {
            zIndex: 1029
          }
        }}>
        {this.props.children}
      </Sidebar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_permission: state.permission,
    store_language: state.language,
    store_sidebar: state.sidebar,
    store_payment: state.payment,
    store_config: state.config
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    toggleSidebar,
    getCustomerPayment,
    getBusinessList
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarComponent))