import Cookies from 'js-cookie'

const cookie_options = { expires: 5 * 365, secure: true }
if (window.location.protocol.indexOf('https') < 0)
  Object.assign(cookie_options, { secure: false, sameSite: 'lax' })

export function getCookie(key) {
  let secure_ck = Cookies.get(`_s${key}`, { domain: process.env.REACT_APP_COOKIEDOMAIN })

  if (!secure_ck) {
    let ck = Cookies.get(key)
    if (ck) {
      ck = (key === '__lang' && ['th', 'en'].indexOf(ck) < 0)
        ? ['th', 'en'].indexOf(atob(ck)) > -1 ? atob(ck) : 'th'
        : ck

      setCookie(key, ck)

      if (ck.indexOf('{') >= 0)
        return JSON.parse(ck) || {}

      return ck
    }
    else
      return ''
  }

  try {
    let value = atob(secure_ck)
    if (value.indexOf('{') >= 0)
      value = JSON.parse(value) || {}
    return value

  } catch (error) {
    if (secure_ck.indexOf('{') >= 0)
      return JSON.parse(secure_ck) || {}
    return secure_ck
  }
}

export function setCookie(key, value, option) {
  let op = Object.assign({}, cookie_options)
  let cc = value

  if (option)
    Object.assign(op, option)

  if (typeof value === 'object') {
    cc = getCookie(key) || {}
    cc = Object.assign({}, cc, value)
    cc = JSON.stringify(cc)
  }

  let b64 = btoa(cc)
  let _key = key === '__lang' ? key : `_s${key}`
  Cookies.set(_key, b64, op)
  if (key !== '__lang')
    Cookies.remove(key)

  return true;
}

export function removeCookie(key, option) {
  if (option) {
    Cookies.remove(`_s${key}`, option);
    Cookies.remove(key, option)
    return;
  }
  
  Cookies.remove(`_s${key}`)
  Cookies.remove(key)
}