import styled, { css } from 'styled-components'

export const PointspotLogoBox = styled.div`
  width: 100%;
  padding: 15px 0px;
`

export const GroupMenu = styled.div`
  height: 100%;
  overflow-y:auto;
`

export const PointspotLogo = styled.img`
  width: 100%;
  height: auto;
`

export const MenuIcon = styled.div`
  width: 20px;
  height: auto;
  font-size: 12px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-right: 15px;
`

export const MenuAllItem = styled.div`
  overflow: hidden;
  transition: all 0.5s ease;

  ${props => props.isOpen
    ? css`
        border-bottom: 1px solid #dee2e6;

        max-height: calc(42px * ${props.itmesSubMenu});
        @media (max-width: 990px) {
          max-height: calc(39px * ${props.itmesSubMenu});
        }
      `
    : css`
        border-bottom: 0px solid #dee2e6;
        max-height: 0px;
      `
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  
  overflow: hidden;
  padding: 12px 16px;
  border-left: 5px solid ${props => props.theme.primary};

  ${props => props.active
    ? css`background-color: #00000015;`
    : null
  }

  ${props => props.disable
    ? css`opacity: 0.5; cursor: not-allowed;`
    : css`cursor: pointer;`
  }

  &:hover{
    ${props => props.active ? css`background: #00000015;` : css`background: #00000009;`}
  }
  
`

export const SidebarBox = styled.div`
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const IconMenu = styled.i`
  ${props => props.active
    ? css`color: ${props => props.theme.primary};`
    : css`color: ${props => props.theme.gray};`
  }
`

export const TitleMenu = styled.div`
  width: 100%;
`

export const SubMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px;
  
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
`

export const MenuIconAngle = styled.div`
  width: 20px;
  height: auto;
  font-size: 16px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s ease;
  ${props => props.isOpen
    ? css`transform: rotate(180deg);`
    : css`transform: rotate(0deg);`
  }
`

export const UserDetail = styled.div`
  padding: 0px 20px 15px 20px;
 
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
`

export const UserImage = styled.img`
  width: 60px;
  min-width: 60px;

  height: auto;
  margin: 0px 10px;
  border-radius: 50%;
  transition: all 0.2s ease;

  @media (max-width: 990px) {
    width: 50px;
    min-width: 50px;
  }
`

export const GroupBottom = styled.div`
  border-top: 1px solid #dee2e6;
`