import { setCookie, getCookie, removeCookie } from '../libs/cookie'
import config from '../config'

const { DEBUG } = config.diagnostic

let cookiesOptions = { domain: process.env.REACT_APP_COOKIEDOMAIN }

export const setRedirect = (__dst, __tkn) => {
  removeCookie('__tkn', cookiesOptions) //remove cookie from previous version which set it with domain attr

  setCookie('__dst', __dst)
  setCookie('__tkn', __tkn)
  setCookie('__ref', window.document.referrer || process.env.REACT_APP_LOYALTY)
  DEBUG("setRedirect", __tkn, getCookie('__tkn'));
}

export const setLoyalty = () => {
  setCookie('__dst', 'Loyalty')
  setCookie('__tkn', '1')
  setCookie('__ref', process.env.REACT_APP_LOYALTY);
}

export const chkRedirect = (isLogin) => {
  return new Promise((resolve, reject) => {
    const token = getCookie('__customToken');
    DEBUG("[chkRedirect]", isLogin, token);
    if (isLogin && token) {
      reject();
    } else if (token) {
      resolve(token);
    } else {
      resolve();
    }
  })
}