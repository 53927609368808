import { INVOICE } from "../action_const";

let initState = {
  isLoading: false,
  invoices: [],
  retryingCharge: "",
  billingDetail: [],
  loadingInfo: false,
  usages: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case INVOICE.INVOICE_LOADING:
      return Object.assign({}, state, {
        isLoading: action.loading,
      });
    case INVOICE.GET_CUSTOMER_INVOICES:
      return Object.assign({}, state, {
        invoices: action.data,
      });
    case INVOICE.RETRY_CHARGE_INVOICE:
      return Object.assign({}, state, {
        retryingCharge: action.data,
      });
    case INVOICE.BILLING_DETAIL:
      return Object.assign({}, state, {
        billingDetail: action.data,
        loadingInfo: false,
      });
    case INVOICE.CLEAR_BILLING_DETAIL:
      return Object.assign({}, state, {
        billingDetail: [],
      });
    case INVOICE.SET_LOADING_INFO:
      return Object.assign({}, state, {
        billingDetail: action.data,
      });
    case INVOICE.GET_CREDITS_USAGE_INVOICE:
      return Object.assign({}, state, {
        usages: action.data,
      });
    default:
      return state;
  }
};
