export default {
  locale: 'th',
  language_thailand: 'ภาษาไทย',
  language_english: 'English',
  change_language: 'Change language',
  hello: 'สวัสดีครับ!',
  pointspot: 'Pointspot',
  continue_with_google: 'เข้าสู่ระบบด้วย Google',
  continue_with_facebook: 'เข้าสู่ระบบด้วย Facebook',
  accept_terms: 'การล็อคอินเข้าสู่ระบบนี้ ท่านยอมรับ',
  accept_terms_create: 'การสร้างบัญชีผู้ใช้นี้ ท่านยอมรับ',
  terms_conditions: 'เงื่อนไขการใช้บริการ',
  privacy_policy: 'นโยบายข้อมูลส่วนบุคคล',
  signup: 'สร้างบัญชีร้านค้า/ธุรกิจบน Pointspot',
  signin: 'เข้าสู่ระบบ',
  signout: 'ออกจากระบบ',
  email: 'อีเมล',
  change_email: 'เปลี่ยนอีเมล',
  user_information: 'ข้อมูลผู้ใช้งาน',
  password: 'รหัสผ่าน',
  reset_password: 'รีเซ็ตรหัสผ่าน',
  forget_password: 'ลืมรหัสผ่าน',
  singin_with_pointspot_account: 'เข้าสู่ระบบด้วยบัญชี Pointspot',
  email_or_password_is_incorrect: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
  congratulations: 'ขอแสดงความยินดี',
  email_verified: 'อีเมลของคุณได้รับการยืนยันแล้ว',
  firstname: 'ชื่อจริง',
  lastname: 'นามสกุล',
  set_password: 'ตั้งค่ารหัสผ่าน',
  confirm_password: 'ยืนยันรหัสผ่านอีกครั้ง',
  contact_phone: 'เบอร์โทรศัพท์ที่สามารถติดต่อได้',
  submit: 'ยืนยัน',
  confirm: 'ยืนยัน',
  back: 'ย้อนกลับ',
  or: 'หรือ',
  note: 'หมายเหตุ',
  email_incorrect: 'อีเมลไม่ถูกต้อง',
  email_already_in_use: 'อีเมลมีผู้ใช้แล้ว',
  weak_password: 'รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร',
  password_not_match: 'รหัสผ่านไม่เหมือนกัน',
  check_email: 'กรุณาตรวจสอบอีเมล',
  logout: 'ออกจากระบบ',
  crop: 'ตัด',
  cancel: 'ยกเลิก',
  save: 'บันทึก',
  create: 'สร้าง',
  next: 'ต่อไป',
  and: 'และ',
  business: 'ธุรกิจ',
  update: 'อัปเดท',
  create_business: 'สร้างธุรกิจ',
  credit_card: 'บัตรเครดิต',
  debit_card: 'บัตรเดบิต',
  edit: 'แก้ไข',
  edit_data: 'แก้ไขข้อมูล',
  product: 'ผลิตภัณฑ์',
  notification: 'แจ้งเตือน',
  confirm_logout: 'คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ ?',
  retry: 'ลองอีกครั้ง',
  expired: 'หมดอายุ',
  select: 'เลือก',

  //Alert Message
  cannot_completed_request: 'ไม่สามารถทำตามที่ร้องขอได้\nกรุณาลองใหม่อีกครั้ง',
  edit_image_success: 'แก้ไขรูปภาพส่วนตัวสำเร็จ',
  reset_password_success: 'รีเซ็ตรหัสผ่านสำเร็จ',
  add_creditcard_success: 'เพิ่มบัตรสำเร็จ',
  delete_creditcard_success: 'ลบบัตรสำเร็จ',
  default_creditcard_success: 'ตั้งค่าใช้งานบัตรสำเร็จ',
  edit_invoice_address_success: 'แก้ไขที่อยู่ใบแจ้งหนี้ / ใบเสร็จรับเงินสำเร็จ',
  foreign_pay_detail: 'กรณีที่ชำระเงินค่าบริการด้วยบัตรเดบิต กรุณาติดต่อธนาคารเพื่อเปิดใช้งานการชำระเงินไปยังต่างประเทศ',
  card_verification_remark: 'Pointspot จะทำการทดสอบเรียกเก็บเงินเป็นจำนวน 1 บาท เมื่อท่านทำการเพิ่มบัตร และจะทำการคืนเงินเมื่อรายการเสร็จสมบูรณ์',

  // Proflie
  profile: 'โปรไฟล์',
  wallet_id: 'Wallet ID',
  check_email_for_reset_password: 'ลิ้งสำหรับการรีเซ็ตรหัสผ่านถูกส่งเข้าไปยัง',
  pointspot_account: 'บัญชี Pointspot',
  new_password: ' รหัสผ่านใหม่',
  password_changed: 'รหัสผ่านถูกเปลี่ยนแล้ว',
  signin_with_new: 'ผู้ใช้สามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่',
  resend_email: 'ส่งอีเมลอีกครั้ง',
  dont_receive_email: 'ไม่ได้รับอีเมลใช่ไหม ?',
  please_verify_email: 'กรุณายืนยันอีเมล',
  please_check_email: 'โปรดตรวจสอบอีเมลของท่าน',
  please_check_email2: 'และกดยืนยันจากอีเมลที่ทาง Pointspot ส่งให้ที่',
  edit_profile: 'แก้ไขข้อมูลส่วนตัว',
  edit_password: 'แก้ไขรหัสผ่าน',
  select_photo: 'เลือกรูปภาพ',
  name_surname: 'ชื่อ - นามสกุล',
  limit_characters: 'ไม่เกิน 30 ตัวอักษร',
  confirm_email: 'คุณต้องการรีเซ็ตรหัสผ่านสำหรับอีเมลนี้หรือไม่ ?',
  confirm_change_email: 'คุณต้องการเปลี่ยนอีเมลสำหรับผู้ใช้งานนี้หรือไม่ ?',
  send_change_email_success: 'ส่งคำขอเปลี่ยนอีเมลสำเร็จ\nกรุณาตรวจสอบอีเมล เพื่อยืนยันการแก้ไข',
  require_email_reset: 'รีเซ็ตรหัสผ่านสำหรับอีเมลนี้',
  edit_profile_success: 'แก้ไขข้อมูลส่วนตัวสำเร็จ',
  no_results_found: 'ไม่พบรายการ',
  order_by: 'จัดเรียงตาม',
  choose: 'เลือก',

  // Package
  package: 'แพ็คเกจ',
  default_credit_card: 'ใช้งาน',
  set_default_credit_card: 'ใช้งานบัตรใบนี้',
  you_want_delete_credit_card: 'คุณต้องการลบบัตรหรือไม่ ?',
  you_want_set_default_credit_card: 'คุณต้องการใช้งานบัตรใบนี้หรือไม่ ?',
  add_card: 'เพิ่มบัตร',
  add_credit_card: 'เพิ่มบัตรเครดิต / บัตรเดบิต',
  please_add_credit_card: 'กรุณาเพิ่มบัตรเครดิต / บัตรเดบิต',
  credit_card_number: 'หมายเลขบัตร',
  when_doing: 'เมื่อทำการ',
  you_understand_and_accept: 'ท่านเข้าใจและยอมรับ',
  cardholder_name: 'ชื่อผู้ถือบัตร',
  expiration_month: 'เดือนที่หมดอายุ',
  expiration_year: 'ปีที่หมดอายุ',
  delete_credit_card: 'ลบบัตร',

  // Paymant Method
  payment_method: 'การชำระเงิน',
  terms_payment: 'เงื่อนไขการชำระเงิน',
  terms_payment_list: [{
    title: '1. รูปแบบการชำระเงิน',
    description: 'การชำระเงินค่าบริการของ Pointspot จะชำระผ่านบัตรเครดิตในระบบออนไลน์ โดยระบบจะสรุปยอดค่าใช้จ่ายในแต่ละเดือนในวันสุดท้ายของเดือน และทำการตัดชำระเงินจากบัตรที่ได้ตั้งค่าไว้ในระบบ ซึ่งกรณี ระบบไม่สามารถตัดชำระเงินผ่านบัตรได้ ด้วยเหตุผลต่างๆ เช่นบัตรหมดอายุ หรือ วงเงินในบัตรไม่พอ อาจส่งผลทำให้ไม่สามารถใช้บริการได้ จนกว่าจะมีการชำระเงินคงค้างนั้น'
  }, {
    title: '2. บริษัทผู้ให้บริการระบบ Pointspot',
    description: 'บริษัทที่เป็นผู้ให้บริการ Pointspot คือ บริษัทเรดดี้แพลนเน็ตจำกัด ซึ่งจดทะเบียนและตั้งอยู่ที่ เลขที่ 51 อาคารเมเจอร์ ทาวเวอร์ พระราม 9 - รามคำแหง ชั้น 17 ห้อง 1701 - 1706 ถนนพระราม 9 แขวงหัวหมาก เขตบางกะปิ กรุงเทพมหานคร 10240 ราคาค่าบริการที่คิดเป็นราคาที่รวมภาษีมูลค่าเพิ่มแล้ว'
  }, {
    title: '3. ระบบรักษาความปลอดภัยของการชำระเงิน',
    description: 'Pointspot ใช้ระบบชำระเงินออนไลน์จากบริษัท 2c2p ซึ่งเป็นผู้ให้บริการด้านรับชำระเงินออนไลน์ระดับโลก โดยข้อมูลหมายเลขบัตรเครดิตไม่ได้เก็บรักษาไว้ที่ในระบบ Pointspot แต่เก็บรักษาไว้ที่บริษัทผู้ให้บริการชำระเงิน เพื่อเหตุผลด้านความปลอดภัย'
  }],
  terms_accept: "ยอมรับข้อตกลง",
  invoice_payment_method: 'ช่องทางการชำระเงิน',

  // Invoice Receipt
  invoice: 'ใบแจ้งหนี้',
  receipt: 'ใบเสร็จรับเงิน',
  status: 'สถานะ',
  download: 'ดาวน์โหลด',
  balance: 'ยอดเงิน',
  processing: 'กำลังประมวลผล',
  paid: 'จ่ายสำเร็จ',
  failed: 'ไม่สำเร็จ',
  pay: 'ชำระเงิน',
  list_invoice_receipt: 'รายการยอดค่าใช้จ่ายตามรอบบิล',
  usages_receipt: 'รายงานประวัติการใช้งาน',
  usage: 'ยอดการใช้งาน',
  usage_summary: 'ยอดการใช้งานเดือน',
  address_invoice_receipt: 'ที่อยู่ใบแจ้งหนี้ / ใบเสร็จรับเงิน',
  retry_charge_confirm: 'กรุณายืนยันการชำระเงิน',
  payment_success: 'จ่ายเงินสำเร็จ',
  billing_detail: 'รายละเอียด',
  billing_unit_credit: 'เครดิตต่อหน่วย',
  usage_detail: 'รายละเอียดการใช้งาน',
  data_on: 'ข้อมูล ณ วันที่',

  // Month
  January: 'มกราคม',
  February: 'กุมภาพันธ์',
  March: 'มีนาคม',
  April: 'เมษายน',
  May: 'พฤษภาคม',
  June: 'มิถุนายน',
  July: 'กรกฏาคม',
  August: 'สิงหาคม',
  September: 'กันยายน',
  October: 'ตุลาคม',
  November: 'พฤศจิกายน',
  December: 'ธันวาคม',

  // Address 
  company_name: 'ชื่อบริษัท / นิติบุคคล / ชื่อผู้รับ',
  phone: 'เบอร์โทรศัพท์',
  address: 'ที่อยู่',
  address_number: 'เลขที่',
  village_number: 'หมู่ที่',
  village: 'หมู่บ้าน',
  lane: 'ซอย',
  road: 'ถนน',
  district: 'แขวง/ตำบล',
  city: 'เขต/อำเภอ',
  state: 'จังหวัด',
  postal_code: 'รหัสไปรษณีย์',
  country: 'ประเทศ',
  please_fill_detail_english: 'กรุณากรอกรายละเอียดเป็นภาษาอังกฤษเท่านั้น',
  please_fill_information: 'กรุณากรอกข้อมูล',
  please_fill_information_english: 'กรุณากรอกข้อมูลเป็นภาษาอังกฤษ',
  please_fill_information_number: 'กรุณากรอกข้อมูลเป็นตัวเลข',
  please_fill_information_format_phone: 'กรุณากรอกข้อมูลเบอร์โทรศัพท์',
  tax_id: 'เลขประจำตัวผู้เสียภาษี / เลขบัตรประชาชน',

  // Credit Balance
  credit: 'เครดิต',
  prepaid_credit: 'Pointspot เครดิตสำหรับแพ็กเกจ',
  annual_credit: 'Pointspot เครดิตสำหรับรายการส่งเสริมการขาย',
  credit_balance: 'เครดิตคงเหลือ',
  number_rank: 'ลำดับ',
  of: 'ของ',
  activities: 'การดำเนินการ',
  credit_list: 'รายการ',
  debit_action: 'หักยอดเครดิต',
  credit_action: 'เพิ่มยอดเครดิต',
  balance_remain: 'ยอดคงเหลือ',
  balance_transaction: 'ประวัติรายการเครดิต',
  no_balance_transaction: 'ไม่มีประวัติรายการเครดิต',
  balance_status: {
    adjustment: 'ปรับยอดเครดิต',
    applied_to_invoice: 'หักค่าใช้จ่ายตามรอบบิล',
    invoice_too_small: 'ยอดค้างเครดิต',
    usages_on: 'หักค่าใช้จ่ายเดือน',
  },

  // 2c2p
  card_no_require: "กรุณากรอกหมายเลขบัตร",
  card_no_invalid: "หมายเลขบัตรไม่ถูกต้อง",
  exp_month_require: "กรุณากรอกเดือนที่หมดอายุ",
  exp_month_2_digit: "เดือนที่หมดอายุต้องเป็นเลข 2 หลัก",
  exp_month_invalid: "เดือนที่หมดอายุไม่ถูกต้อง",
  exp_year_require: "กรุณากรอกปีที่หมดอายุ",
  exp_year_4_digit: "ปีที่หมดอายุต้องเป็นเลข 2 หลัก",
  card_expired: "บัตรหมดอายุแล้ว",
  cvv_invalid: "CVV ไม่ถูกต้อง",
  add_card_error: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
  add_card_error_cancelled: "เกิดข้อผิดพลาด รายการถูกยกเลิก",
  add_card_error_invalid_number: "เกิดข้อผิดพลาด หมายเลขบัตรไม่ถูกต้อง",
  add_card_error_owner_cancel: "เกิดข้อผิดพลาด พบการยกเลิกจากเจ้าของบัตร",
  add_card_error_bank_unsupport: "เกิดข้อผิดพลาด ระบบไม่สามารถเชื่อมต่อกับธนาคารเจ้าของบัตรนี้ได้",
  add_card_error_expired_card: "เกิดข้อผิดพลาด บัตรหมดอายุ",
  add_card_error_restricted_card: "เกิดข้อผิดพลาด บัตรไม่สามารถใช้งานได้",
  add_card_error_lost_card: "เกิดข้อผิดพลาด บัตรนี้ถูกแจ้งว่าเป็นบัตรที่สูญหาย",
  add_card_error_stolen_card: "เกิดข้อผิดพลาด บัตรนี้ถูกแจ้งว่าเป็นบัตรที่ถูกขโมย",
  add_card_error_insufficient_funds: "เกิดข้อผิดพลาด จำนวนเงินไม่เพียงพอ",
  add_card_error_exceed_withdrawal_limit: "เกิดข้อผิดพลาด จำนวนการใช้งานเกินกำหนด",

  //BillingDetailModal
  close: "ปิด",
  member_usage_description: 'นับจำนวนสมาชิกจาก สมาชิกที่ได้รับบัตรสมาชิก หรือ สมาชิกที่ลงทะเบียนและมีพอยท์',
  transaction_usage_description: 'นับจำนวนรายการจากรายการส่งพอยท์ รายการดึงพอยท์ รายการส่งคูปองโปรโมชั่น รายการคูปองแนบท้าย รายการบัตรกำนัล และรายการออกบัตรสมาชิก',
  notification_usage_description: 'นับจำนวนรายการที่มีการส่งข้อความแจ้งเตือน เฉพาะรายการส่วนที่เกินจากแพ็กเกจ',
  transaction_yearly_usage_description: 'นับจำนวน Basic Transaction จาก รายการส่งพอยท์ รายการดึงพอยท์ และรายการออกบัตรสมาชิก',
  transaction_yearly_credit_usage_description: 'นับจำนวนเครดิตจาก การบรอดแคสต์ การส่งคูปองโปรโมชั่น การส่งคูปองแนบท้าย การส่งบัตรกำนัล และรายการส่วนที่เกินจากแพ็กเกจ',
  remark: "หมายเหตุ",
  current_package: "แพ็กเกจปัจจุบัน",
  package_and_charge: 'แพ็กเกจและค่าบริการ',
  bath:'บาท',
  members: 'สมาชิก',
  member_package: 'สมาชิก',
  transactions: 'รายการ',
  basic_transactions: 'Basic Transactions',
  promotional_transactions: 'Promotional Transactions',
  monthly_free_credits: 'เครดิตฟรีรายเดือน',
  add_ons_credit: 'เครดิตเสริม',
  premium_id: 'Premium ID',
  product_level_points: 'ระบบพอยท์รายสินค้า',
  sender_name: 'ชื่อผู้ส่งข้อความ',
  additional: 'คุณสมบัติเพิ่มเติม',
  sub_total:'รวมเงิน',
  vat:'ภาษีมูลค่าเพิ่ม',
  annual_fee: 'ค่าบริการรายปี',
  net_total:'ยอดสุทธิ',
  broadcast_message :'บรอดแคสต์ข้อความ',
  add_ons: 'ส่วนเสริม',
  during_billing_cycle:'ยอดการใช้งานระหว่างรอบบิล',
  payment_method_1:'ช่องทางการชำระเงิน',
  items: 'รายการ',
  monthly_fee:'ค่าธรรมเนียมรายเดือน',
  quantity:'จำนวน',
  unit_price:'ราคาต่อหน่วย',
  amount: 'ราคา',
  create_date: 'วันที่สร้าง',
  overage: 'ค่าบริการส่วนเกิน',
  broadcast_billing_credit: 'การใช้งานเครดิต',
  remain: 'คงเหลือ',
  free_credit: 'เครดิตฟรี',
  credit_use: 'เครดิตที่ใช้',
  view_details: 'ดูรายละเอียด',
  view_details_less: 'ดูน้อยลง',
  overage_items: {
    overage_basic_transaction: 'Basic Transactions ส่วนเกิน',
    additional_transaction: 'รายการ',
    additional_sms: 'การแจ้งเตือน - SMS',
    additional_email: 'การแจ้งเตือน - EMAIL',
    additional_shappy: 'การแจ้งเตือน - SHAPPY',
    additional_line_noti: 'การแจ้งเตือน - LINE',
    additional_sms_broadcast: 'บรอดแคสต์ - SMS',
    additional_email_broadcast: 'บรอดแคสต์ - EMAIL',
    additional_line_broadcast: 'บรอดแคสต์ - LINE',
    additional_shappy_broadcast: 'บรอดแคสต์ - SHAPPY',
    additional_member: 'สมาชิก',
    additional_sms_inter: 'เบอร์ต่างประเทศ - SMS',
    advance_apis: 'Advance APIs',
    additional_coupon_transaction: 'คูปอง/บัตรกำนัล',
    additional_coupon_sms: 'คูปอง/บัตรกำนัล - SMS',
    additional_coupon_line: 'คูปอง/บัตรกำนัล - LINE',
    additional_coupon_email: 'คูปอง/บัตรกำนัล - EMAIL',
    additional_coupon_shappy: 'คูปอง/บัตรกำนัล - SHAPPY',
    not_send_notification: 'ส่งแบบไม่แจ้งเตือน',
    broadcast: 'บรอดแคสต์',
    notification: 'การแจ้งเตือน',
    additional_sms_collect_points: 'การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - SMS',
    additional_line_collect_points: 'การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - LINE',
    additional_email_collect_points: 'การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - EMAIL',
    additional_shappy_collect_points: 'การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ - SHAPPY',
    points_collect_requests_rejected: 'การแจ้งเตือนรายการแลกรับพอยท์ถูกปฏิเสธ',
    advance_points_expiration_notifications: 'การแจ้งเตือนพอยท์ใกล้หมดอายุ',
    additional_sms_points_expiration: 'การแจ้งเตือนพอยท์ใกล้หมดอายุ - SMS',
    additional_line_points_expiration: 'การแจ้งเตือนพอยท์ใกล้หมดอายุ - LINE',
    additional_email_points_expiration: 'การแจ้งเตือนพอยท์ใกล้หมดอายุ - EMAIL',
    additional_shappy_points_expiration: 'การแจ้งเตือนพอยท์ใกล้หมดอายุ - SHAPPY',
  },
  subscription_coupons: {
    internal_account: "บัญชีภายใน",
    system_change_package: "ส่วนลดปรับแพ็กเกจ",
    special_discount: "ส่วนลดพิเศษ",
    first_month_25_discount: "ส่วนลด 25% สำหรับการอัปเกรดในเดือนแรก",
    first_month_50_discount: "ส่วนลด 50% สำหรับการอัปเกรดในเดือนแรก",
    first_month_75_discount: "ส่วนลด 75% สำหรับการอัปเกรดในเดือนแรก",
    covid_discount: "ส่วนลดพิเศษช่วงโควิด-19",
    trial_package: "ส่วนลดบัญชีทดลองใช้"
  },
  charge_automatically: 'ชำระเงินอัตโนมัติ',
  send_invoice: 'ส่งใบแจ้งหนี้',
  namePackage: {
    "ps-f32": "F32",
    "ps-free-trial": "Free-trial",
    "ps-max": "Max",
    "ps-platinum": "แพลตินัม",
    "ps-gold": "โกลด์",
    "ps-premium": "พรีเมียม",
    "ps-shappy": "Shappy+",
    "ps-pro": "โปร",
    "ps-free": "ฟรี",
    "ps-test": "เทส",
    "ps-test-annual": "เทส",
    "ps-test-annual-2": "เทส",
    "premium-1k": "พรีเมียม",
    "ps-enterprise-10k": "ธุรกิจ",
    "ps-enterprise-42k": "ธุรกิจ",
    "ps-starter": 'Starter',
    "ps-professional": 'Professional',
    "ps-enterprise": 'Enterprise',
  },
}