import package_config from '../../../package.json'

let initState = {
  application: {
    name: '',
    version: ''
  }
}

export default (state = initState, action) => {
  return Object.assign({}, state, {
    application: {
      name: package_config.appDisplay,
      version: package_config.version
    }
  })
}