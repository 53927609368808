import * as UserAPI from '../../apis/user'

import { getCookie, setCookie, removeCookie } from '../../libs/cookie'

import Config from '../../config'
import { PERMISSION } from '../action_const'
import axios from 'axios'
import firebase from '../../libs/firebase'

let cookiesOptions = { domain: process.env.REACT_APP_COOKIEDOMAIN };

export const onAuthStateChanged = () => {
  return dispatch => {
    getAuthStateChanged()
      .then(user => {
        return getUserProfile(user)
      })
      .then(data => {
        updateUserStatus(true);
        dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: data })
      })
      .catch(error => {
        if (error.code === 'UNREGISTERED') error.providerData.delete()
        dispatch({ type: PERMISSION.USER_NOT_AUTH })
      })

  }
}

const updateUserStatus = (isLogin) => {
  let dateTime = new Date()
  let currentUser = firebase.auth().currentUser || {}

  if (!currentUser.uid)
    return null

  Object.assign(window.ps_auth, {
    currentUser: isLogin && currentUser.emailVerified ? JSON.parse(JSON.stringify(currentUser)) : null,
    uid: isLogin ? currentUser.uid : null,
    email: isLogin ? currentUser.email : null
  })
  return firebase.firestore().collection('user_status').doc(currentUser.uid).set({ isLogin: isLogin, lastLogin: dateTime }, { merge: true })
}

export const onAuthRedirectResult = () => {
  return dispatch => {
    firebase.auth().getRedirectResult()
      .then(result => {
        if (result.user) {
          dispatch({ type: PERMISSION.GET_SOCIAL_CREDENTIAL, data: result })
        }
      })
  }
}

export const removeSocialCredential = () => {
  return dispatch => {
    dispatch({ type: PERMISSION.REMOVE_SOCIAL_CREDENTAIL })
  }
}

const getUserProfile = (user) => {
  return new Promise((resolve, reject) => {
    firebase.firestore().collection('account').doc(user.uid).get()
      .then(doc => {
        if (doc.exists) {
          let profile = doc.data()
          profile.emailVerified = user.emailVerified
          Object.assign(window.ps_auth, { uid: user.uid })
          resolve(profile)
        }
        else reject({ code: 'UNREGISTERED', providerData: user })
      })
  })
}

const getAuthStateChanged = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.auth().currentUser.getIdToken()
          .then(tkn => {
            setCookie('__fbToken', tkn)
            return resolve(user)
          })
          .catch(error => reject({ code: 'UNAUTH' }))
      }
      else reject({ code: 'UNAUTH' })
    })
  })
}

export const createUserWithEmail = (data, providerData) => {
  let _tkn;
  let userDisplayName = data.first_name + ' ' + data.last_name
  return dispatch => {
    dispatch({ type: PERMISSION.CREATE_USER_REQUEST })
    firebase.auth().createUserWithEmailAndPassword(data.email, data.password)
      .then(({ user }) => {
        Object.assign(window.ps_auth, { uid: firebase.auth().currentUser.uid })
        return firebase.auth().currentUser.updateProfile({ displayName: userDisplayName.trim(), password: data.password })
          .then(() => {
            userLinkSocialAccount(providerData)
            return user.getIdToken(true)
          })
      })
      .then(async token => {
        _tkn = token
        await UserAPI.updatePassword(token, data.password)
        return UserAPI.register(token,
          Object.assign({}, data, {
            name: data.first_name,
            display_name: userDisplayName,
            tap_ref_code: getCookie('tap_ref_code'),
            tap_tracking_id: getCookie('tap_tracking_id'),
            current_url: data.current_url,
            referrer_url: data.referrer_url,
            gclid: data.gclid,
            utm_source: data.utm_source,
            utm_medium: data.utm_medium,
            utm_campaign: data.utm_campaign
          }))
      })
      .then(res => {
        if (!res.data.result) {
          if (firebase.auth().currentUser) firebase.auth().currentUser.delete()
          return dispatch({
            type: PERMISSION.CREATE_USER_FAIL,
            error: { code: 'register-fail', message: 'Register fail' }
          })
        }

        UserAPI.sendEmailVerification(_tkn)
        dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: res.data.data })
      })
      .catch(error => {
        if (firebase.auth().currentUser) firebase.auth().currentUser.delete()
        dispatch({ type: PERMISSION.CREATE_USER_FAIL, error: error })
      })
  }
}

export const loginWithEmailAndPassword = (email, password) => {
  return dispatch => {
    dispatch({ type: PERMISSION.USER_LOGIN_REQUEST })
    axios({
      method: 'POST',
      url: process.env.REACT_APP_RP_AUTH_API + '/auth/signinWithPassword',
      data: {
        username: email,
        password: password
      }
    }).then(response_data => {
      let data = response_data.data
      if (!data || !data.result)
        return dispatch({ type: PERMISSION.USER_LOGIN_FAIL, error: 'Username or Password Invalid' });

      let { customToken } = data.data;
      if (!customToken)
        return dispatch({ type: PERMISSION.USER_LOGIN_FAIL, error: 'Username or Password Invalid' });

      firebase.auth().signInWithCustomToken(customToken)
        .then(user => getUserProfile(user.user || user))
        .then(data => {
          updateUserStatus(true);
          dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: data });
        })
        .catch(error => {
          dispatch({ type: PERMISSION.USER_LOGIN_FAIL, error: error });
        });
    });
  };
};

export const signInWithCustomToken = (customToken) => {
  return dispatch => {
    firebase.auth().signInWithCustomToken(customToken)
    .then(user => getUserProfile(user.user || user))
    .then(data => {
      updateUserStatus(true);
      dispatch({ type: PERMISSION.USER_LOGIN_SUCCESS, data: data });
    })
    .catch(error => {
      removeCookie('__customToken', cookiesOptions);
      dispatch({ type: PERMISSION.USER_LOGIN_FAIL, error: error });
    });
  }
}

const userLinkSocialAccount = (providerData) => {
  return new Promise((resolve, reject) => {
    if (providerData) {
      firebase.auth().currentUser.linkWithCredential(providerData.credential).then(resolve).catch(reject);
    } else resolve();
  });
};

export const shouldRedirect = (val) => {
  return dispatch => {
    dispatch({ type: PERMISSION.SET_REDIRECT_ROUTE, data: val });
  };
};

export const userLogout = () => {
  Object.assign(window.ps_auth, { uid: null })
  return dispatch => {
    updateUserStatus(false)
      .then(() => firebase.auth().signOut())
      .then(() => dispatch({ type: PERMISSION.USER_LOGOUT_SUCCESS }))
      .catch((err) => {
        Config.diagnostic.ERROR('userLogout', err && err.message)
      })
  }
}

export const removeLoginError = () => {
  return dispatch => {
    dispatch({ type: PERMISSION.REMOVE_LOGIN_ERROR });
  };
};

export const sendEmailVerification = () => {
  return dispatch => {
    let user = firebase.auth().currentUser;
    user.getIdToken()
      .then(token => UserAPI.sendEmailVerification(token))
      .then(res => {
        if (!res.data.result) {
          if (res.data.errorMessage.code === 'auth/email-already-verified')
            return dispatch({ type: 'EMAIL_ALREADY_VERIFIED' });
        }
      })
      .catch(err => {
      });
  };
};

export const getVerifyEmail = (code, callback) => {
  return dispatch => {
    UserAPI.getVerifyEmail(code)
      .then(res => {
        if (!res.data.result) return callback('/');

        window.ps_auth = res.data.data
        dispatch({ type: 'EMAIL_ALREADY_VERIFIED' });
        return callback('/verified');
      })
      .catch(() => {
        return callback('/');
      });
  };
};

export const uploadAvatarUser = (filebase64, callback) => {
  return dispatch => {
    dispatch({ type: PERMISSION.UPDATE_USER_LOADING })

    let uid = firebase.auth().currentUser.uid
    firebase.storage().ref('Avatar').child(uid).putString(filebase64, 'data_url')
      .then(async (snapshot) => {
        let downloadUrl = await snapshot.ref.getDownloadURL()
        firebase.firestore().collection('account').doc(uid).update({
          photoURL: downloadUrl
        }).then(() => {
          if (typeof callback === 'function') callback(null, { result: true })
          return dispatch({ type: PERMISSION.UPDATE_AVATAR_USER, data: downloadUrl })

        })
          .catch(catchErr => {
            console.log(catchErr)
            if (typeof callback === 'function') callback(catchErr)
            return dispatch({ type: PERMISSION.UPDATE_AVATAR_USER, data: '' })

          })
      }).catch(err => {
        console.log(err)
        if (typeof callback === 'function') callback(err)
        return dispatch({ type: PERMISSION.UPDATE_AVATAR_USER, data: '' })

      })
  }
}

export const uploadProfileUser = (data, callback) => {
  return dispatch => {
    dispatch({ type: PERMISSION.UPDATE_USER_LOADING })
    let uid = firebase.auth().currentUser.uid

    firebase.firestore().collection('account').doc(uid).update({
      name: data.name,
      last_name: data.last_name,
      phone: data.phone
    }).then(() => {
      if (typeof callback === 'function') callback(null, data)
      return dispatch({ type: PERMISSION.UPDATE_PROFILE_USER, data: data })

    }).catch(err => {
      console.log(err)
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: PERMISSION.UPDATE_PROFILE_USER, data: '' })

    })
  }
}

export const resetPasswordByEmail = (email, locale, callback) => {
  return dispatch => {
    dispatch({ type: PERMISSION.SENT_EMAIL_RESETPASSWORD_LOADING })
    UserAPI.sendResetPassword(email, locale)
      .then(() => {
        if (typeof callback === 'function') callback(null, { result: true })
        return dispatch({ type: PERMISSION.SENT_EMAIL_RESETPASSWORD })

      })
      .catch((error) => {
        console.log('catch')
        if (typeof callback === 'function') callback(error)
        return dispatch({ type: PERMISSION.SENT_EMAIL_RESETPASSWORD })

      })
  }
}

export const changeEmail = (email, locale, callback) => {
  return dispatch => {
    dispatch({ type: PERMISSION.CHANGE_EMAIL_LOADING })
    UserAPI.changeEmailAccount(email, locale)
      .then((res) => {
        let data = res.data
        if (typeof callback === 'function') callback(data)
        return dispatch({ type: PERMISSION.CHANGE_EMAIL_SUCCESS })

      })
      .catch((error) => {
        if (typeof callback === 'function') callback(error)
        return dispatch({ type: PERMISSION.CHANGE_EMAIL_SUCCESS })

      })
  }
}